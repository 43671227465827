document.addEventListener('DOMContentLoaded', function () {
    var wallopEl = document.querySelector('.Wallop');
    var wallop = new Wallop(wallopEl);

    // To start Autoplay, just call the function below
    // and pass in the number of seconds as interval
    // if you want to start autoplay after a while
    // you can wrap this in a setTimeout(); function
    autoplay(3500);

    // This a a helper function to build a simple
    // auto-play functionality. 
    function autoplay(interval) {
      var lastTime = 0;  

      function frame(timestamp) {
        var update = timestamp - lastTime >= interval;

        if (update) {
          wallop.next();
          lastTime = timestamp;
        }

        requestAnimationFrame(frame);
      }

      requestAnimationFrame(frame);
    };
    
    /*
    Listen to keydown and update wallop
    */
    window.onkeydown = function(e) {
      switch (e.keyCode) {
        case 37:
          wallop.previous();
          break;
        case 39:
          wallop.next();
          break;
      }
    };
    
});